/* eslint-disable camelcase */
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Layout from './Layout';
import LoginPage from '../pages/login';
import PasswordReset from '../pages/passwordReset';
import DashboardPage from '../pages/dashboard';
import IdentitiesPage from '../pages/identities';
import BillingPage from '../pages/billing';
import SettingsPage from '../pages/settings';
import UsersPage from '../pages/users';
import AuthGate from './AuthGate';
import HomeLayout from './HomeLayout';

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login">
          <HomeLayout>
            <LoginPage />
          </HomeLayout>
        </Route>
        <Route exact path="/signup">
          <HomeLayout>
            <SignUp />
          </HomeLayout>
        </Route>
        <Route exact path="/reset_password/:token">
          <HomeLayout>
            <PasswordReset />
          </HomeLayout>
        </Route>
        <Route path="/">
          <DashboardRouter />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function SignUp() {
  return <div>SignUp</div>;
}

function DashboardRouter() {
  const {
    accountPortalBilling,
    accountManagement,
    identityDropdown,
  } = useFlags();
  return (
    <AuthGate>
      <Layout>
        <Switch>
          {identityDropdown && (
            <Route path="/identities">
              <IdentitiesPage />
            </Route>
          )}
          {accountManagement && (
            <Route path="/users">
              <UsersPage />
            </Route>
          )}
          {accountPortalBilling && (
            <Route path="/billing">
              <BillingPage />
            </Route>
          )}
          <Route path="/settings">
            <SettingsPage />
          </Route>
          <Route path="">
            <DashboardPage />
          </Route>
          <Route path="*">
            <Redirect to="/settings" />
          </Route>
        </Switch>
      </Layout>
    </AuthGate>
  );
}
