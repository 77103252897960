import React from 'react';
import UserList from 'mirage-components/dist/User/List';
import UserCreate from 'mirage-components/dist/User/Create';
import VerifyModel from 'mirage-components/dist/User/VerifyModal';
import { useIdentities } from 'mirage-api-client/dist/identity';
import { login } from 'mirage-api-client/dist/auth';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import AddIcon from '@material-ui/icons/Add';
import Row from 'mirage-components/dist/Row';
import Fab from '@material-ui/core/Fab';
import {
  useUsers,
  useUser,
  update,
  createChildUser,
  deleteChildUser,
  resetChildPassword,
} from 'mirage-api-client/dist/user';
import { makeStyles, useMediaQuery, Button } from '@material-ui/core';
import SearchAndSort from 'mirage-components/dist/SearchAndSort';
import { hasPermission } from 'mirage-api-client/dist';

const useStyles = makeStyles({
  buttonRow: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  fab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
});

async function onCreate(data) {
  return createChildUser(data);
}

async function verified(email, password) {
  return login(email, password);
}

const options = [
  { key: 'email', display: 'Email' },
  {
    key: 'createdAt',
    display: 'Created At',
  },
];

const searchableFields = ['email'];

export default function Users() {
  const [identities] = useIdentities();
  const [allUsers] = useUsers();
  const [user] = useUser();

  const users = React.useMemo(() => {
    return allUsers ? allUsers.filter(u => u.uid !== user.uid) : [];
  }, [allUsers, user]);

  const { newSnackBar } = useSnackBar();
  const desktop = useMediaQuery('(min-width: 680px)');
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [verifyOpen, setVerifyOpen] = React.useState(false);
  const [shouldSubmit, setShouldSubmit] = React.useState(false);
  const [usersSorted, setUsersSorted] = React.useState(users);

  function handleSort(data) {
    setUsersSorted(data);
  }

  async function handleSubmit(fields) {
    return new Promise((resolve, reject) => {
      setShouldSubmit({ resolve, reject, fields, type: 'user' });
      setVerifyOpen(true);
    });
  }

  async function handleReset(userId) {
    return new Promise((resolve, reject) => {
      setShouldSubmit({ resolve, reject, fields: userId, type: 'reset' });
      setVerifyOpen(true);
    }).catch(e => {
      if (e && e.message) {
        newSnackBar({
          message: e.message,
          type: 'error',
          position: { vertical: 'top', horizontal: 'center' },
        });
      }
    });
  }

  async function handleUpdate(userId, data) {
    return update(userId, data);
  }

  async function onVerify(data) {
    const isVerified = await verified(data.email, data.password);
    if (isVerified !== true) {
      newSnackBar({
        message: 'Invalid Credentials',
        type: 'error',
        position: { vertical: 'top', horizontal: 'center' },
      });
    } else {
      setVerifyOpen(false);
      const { resolve, reject, fields, type } = shouldSubmit;

      if (type === 'user') {
        fields.masterPassword = data.password;

        try {
          await onCreate(fields);
          resolve();
        } catch (e) {
          reject(new Error(e.response ? e.response.data.error : e.message));
        }
      } else if (type === 'reset') {
        try {
          await resetChildPassword(fields, data.password);
          newSnackBar({
            message:
              'Password reset. An email has been sent with a temporary password',
            type: 'success',
          });
          resolve();
          setOpen(false);
        } catch (e) {
          reject(e);
        }
      }
    }
  }

  async function handleDelete(userId) {
    await deleteChildUser(userId);
    newSnackBar({
      message: 'User deleted',
      type: 'success',
    });
    return true;
  }

  if (!user) return null;

  return (
    <>
      <Row className={classes.buttonRow}>
        <SearchAndSort
          data={users}
          options={options}
          defaultValue="email"
          onChange={handleSort}
          searchableFields={searchableFields}
        />
        {hasPermission(user, 'user.write') && (
          <UserCreate
            identities={identities}
            onSubmit={handleSubmit}
            open={open}
            setOpen={setOpen}
          >
            {desktop ? (
              <Button variant="contained" color="primary">
                Create User
              </Button>
            ) : (
              <Fab
                color="primary"
                aria-label="create identity"
                className={classes.fab}
              >
                <AddIcon />
              </Fab>
            )}
          </UserCreate>
        )}
      </Row>
      <VerifyModel
        email={user.email}
        onVerify={onVerify}
        open={verifyOpen}
        close={() => {
          const { reject } = shouldSubmit;
          reject();
          setVerifyOpen(false);
        }}
      />
      {hasPermission(user, 'user.write') && (
        <UserList
          identities={identities || []}
          users={usersSorted || []}
          onChange={handleUpdate}
          onDelete={handleDelete}
          onReset={handleReset}
          canEdit={hasPermission(user, 'user.write')}
        />
      )}
    </>
  );
}
