const firebase = {
  apiKey: 'AIzaSyD5BvPTvV-bE4-pXTkyEn9QiWbkTodPtPY',
  authDomain: 'mirage-eli.firebaseapp.com',
  databaseURL: 'https://mirage-eli.firebaseio.com',
  projectId: 'mirage-eli',
  storageBucket: 'mirage-eli.appspot.com',
  messagingSenderId: '1039217910993',
  appId: '1:1039217910993:web:6ad2da5558e5956dcdd1f0',
};

const baseUrl = 'https://eli.api.mirageid.com';
const websiteUrl = 'https://stag.mirageid.com';

const launchDarkly = {
  clientID: '5dc308f0fec6f208c5830095',
};

const stripeToken = 'pk_test_WUSP5RjTKLLtYI31wHHEAQPZ';

export default {
  firebase,
  baseUrl,
  websiteUrl,
  launchDarkly,
  stripeToken,
};
