import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Row from 'mirage-components/dist/Row';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Divider } from '@material-ui/core';
import logo from '../assets/logo.png';
import config from '../config';

const { websiteUrl } = config;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2})`,
    overflowY: 'auto',
  },
  toolbar: {
    minHeight: 48,
    justifyContent: 'space-between',
  },
  logo: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 7,
      left: `calc(50vw - 75px)`,
    },
  },
  menuButton: {
    marginLeft: 2,
    menuRight: 2,
  },
  drawer: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileCloseMenuButton: {
    marginLeft: 5,
  },
}));

const menuButtons = [
  {
    label: 'Features',
    href: `${websiteUrl}/features`,
  },
  {
    label: 'Contact Us',
    href: `${websiteUrl}/contact-us`,
  },
  {
    label: 'Pricing',
    href: `${websiteUrl}/pricing`,
  },
  {
    label: 'Signup',
    href: `${websiteUrl}/register`,
  },
  {
    label: 'Download',
    href: `${websiteUrl}/download`,
  },
];

export default function HomeLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div className={classes.root}>
      <TopBar mobile={!matches} />
      <main className={classes.content}>{children}</main>
    </div>
  );
}

function TopBar({ mobile }) {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="default"
      elevation={1}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        {mobile && <DrawerMenu />}
        <Link href={websiteUrl}>
          <img src={logo} alt="Mirage ID" className={classes.logo} />
        </Link>
        {!mobile && <DesktopMenu />}
      </Toolbar>
    </AppBar>
  );
}

function DesktopMenu() {
  const classes = useStyles();

  return (
    <Row>
      {menuButtons.map(({ label, href }) => (
        <Button
          key={href}
          color="inherit"
          href={href}
          className={classes.menuButton}
        >
          {label}
        </Button>
      ))}
    </Row>
  );
}

function DrawerMenu() {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return (
    <>
      <IconButton
        onClick={open}
        color="inherit"
        aria-label="open menu"
        edge="start"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={isOpen}
        onClose={close}
        variant="temporary"
        PaperProps={{ className: classes.drawer }}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={close}
            color="inherit"
            aria-label="close menu"
            className={classes.mobileCloseMenuButton}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
        </div>
        <List disablePadding>
          {menuButtons.map(({ label, href }) => {
            return (
              <MenuButton label={label} key={label} href={href} close={close} />
            );
          })}
        </List>
      </Drawer>
    </>
  );
}

function MenuButton({ label, href }) {
  return (
    <ListItem href={href} button component={Link} key={label}>
      <ListItemText primary={label} inset />
    </ListItem>
  );
}
